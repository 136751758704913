<template>
  <div>
    <el-card>
      <el-form :inline="true" ref="form" :model="form">
        <div>
          <el-form-item prop="date" label="时间维度">
            <el-date-picker v-model="form.date" type="daterange" align="right" unlink-panels range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :clearable="false"
              @change="search()">
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="dateType">
            <el-radio-group v-model="form.dateType" size="small" @input="search()">
              <el-radio-button :label="1">按天</el-radio-button>
              <el-radio-button :label="2">按月</el-radio-button>
              <el-radio-button :label="3">按季</el-radio-button>
              <el-radio-button :label="4">按年</el-radio-button>
            </el-radio-group>
          </el-form-item>
<!--          <el-form-item prop="keyword">
            <el-input v-model="form.keyword" placeholder="请输入搜索关键词" clearable size="small"></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button @click="search()" size="small" type="primary">搜索</el-button>
            <el-button size="small" @click="$refs.form.resetFields();search()">重置</el-button>
          </el-form-item>
        </div>
        <div>
          <el-form-item prop="queryDimension" label="查询维度" style="margin-right: 100px;">
            <el-checkbox-group v-model="form.queryDimension" @change="search()">
              <el-checkbox :label="1">按仓库</el-checkbox>
              <el-checkbox :label="2">按货主</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item prop="status" label="订单状态">
            <el-checkbox-group v-model="form.status" @change="search()">
              <el-checkbox :label="1">待审核</el-checkbox>
              <el-checkbox :label="2">已审核</el-checkbox>
              <el-checkbox :label="3">已完成</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>

      <el-table v-loading="tableLoading" :data="tableData" style="width: 100%;" border>
        <el-table-column header-align="center" align="center" prop="date" label="日期">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="tenant" label="仓库"
          v-if="form.queryDimension.includes(1)">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="owner" label="货主"
          v-if="form.queryDimension.includes(2)">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="orderQuantity" label="订单数量">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="totalPrice" label="总金额">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="skuQuantity" label="商品数(SKU)">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="totalSaleQuantity" label="总商品销售量">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="productSaleQuantity" label="产成品销售量">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="teaMaterialSaleQuantity" label="散茶销售量">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="semimanufactureTeaSaleQuantity" label="准成品销售量">
        </el-table-column>
        <el-table-column header-align="center" align="center" prop="packMaterialSaleQuantity" label="包装物料销售量">
        </el-table-column>
      </el-table>
      <el-pagination :current-page.sync="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
        :total="total" layout="total, sizes, prev, pager, next, jumper" @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  import {
    dateFormat
  } from '@/lib/utils'
  export default {
    components: {},
    data() {
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      const end = new Date()
      const startStr = dateFormat('yyyy-MM-dd', start)
      const endStr = dateFormat('yyyy-MM-dd', end)

      return {
        form: {
          date: [startStr, endStr],
          dateType: 1,
          keyword: '',
          queryDimension: [],
          status: [2, 3]
        },
        tableLoading: false,
        tableData: [],
        currentPage: 1,
        pageSize: 20,
        total: 0
      }
    },
    activated() {
      this.getTableData()
    },
    methods: {
      sizeChangeHandle (pageSize) {
        this.pageSize = pageSize
        this.currentPage = 1
        this.getTableData()
      },
      currentChangeHandle (currentPage) {
        this.currentPage = currentPage
        this.getTableData()
      },
      search() {
        this.currentPage = 1
        this.getTableData()
      },
      getTableData() {
        console.log(this.form.date)
        this.tableLoading = true
        this.$http.get('/statistics/saleOrderStatistics', {
          params: {
            page: this.currentPage,
            limit: this.pageSize,
            dateStart: this.form.date[0],
            dateEnd: this.form.date[1],
            dateType: this.form.dateType,
            keyword: this.form.keyword,
            queryDimension: this.form.queryDimension.join(','),
            status: this.form.status.join(',')
          }
        }).then(({
          data: res
        }) => {
          let {
            code,
            msg,
            data
          } = res
          console.log(code, msg, data)
          if (code === 0 && data) {

            let {
              records,
              current,
              total
            } = data
            this.currentPage = current * 1
            this.total = total * 1
            this.tableData = records && records.length ? records : []
          }
          if (code !== 0) {
            this.$message.error(msg)
          }
          this.tableLoading = false
        })
      }
    }
  }
</script>

<style>
</style>
